import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/admin": [34,[6]],
		"/admin/battery": [35,[6]],
		"/admin/battery/create": [37,[6]],
		"/admin/battery/[id]": [36,[6]],
		"/admin/cfg-sig": [38,[6]],
		"/admin/country-limits": [39,[6]],
		"/admin/country-limits/create": [40,[6]],
		"/admin/device-compatibility": [41,[6]],
		"/admin/device-compatibility/create": [43,[6]],
		"/admin/device-compatibility/[id]": [42,[6]],
		"/admin/discount": [44,[6]],
		"/admin/discount/create": [45,[6]],
		"/admin/firmwares": [46,[6]],
		"/admin/linked-devices": [47,[6]],
		"/admin/logs": [48,[6]],
		"/admin/orders": [49,[6]],
		"/admin/orders/export-vat": [50,[6]],
		"/admin/products": [51,[6]],
		"/admin/products/create": [53,[6]],
		"/admin/products/[id]": [52,[6]],
		"/admin/users": [54,[6]],
		"/admin/users/[id]": [55,[6]],
		"/(app)/available-hacks-old": [11,[2]],
		"/(app)/available-hacks": [9,[2]],
		"/(app)/available-hacks/payment-failed": [10,[2]],
		"/(app)/download-v2": [13,[2]],
		"/(app)/download": [12,[2]],
		"/(app)/login": [14,[2]],
		"/(app)/posts/legality-and-guidelines/is-it-legal-to-hack-drones": [15,[2]],
		"/(app)/register": [16,[2]],
		"/(app)/reset-password": [17,[2]],
		"/(app)/reset-password/[id]": [18,[2]],
		"/(app)/two-factor": [19,[2]],
		"/(app)/user": [20,[2,3]],
		"/(app)/user/orders": [21,[2,3]],
		"/(app)/user/orders/thank-you/[id]": [22,[2,3]],
		"/(app)/user/reseller/dashboard": [23,[2,3,4]],
		"/(app)/user/reseller/order-create": [24,[2,3,4]],
		"/(app)/user/settings/computers": [25,[2,3,5]],
		"/(app)/user/settings/computers/[id]": [26,[2,3,5]],
		"/(app)/user/settings/logs": [27,[2,3,5]],
		"/(app)/user/settings/password": [28,[2,3,5]],
		"/(app)/user/settings/profile": [29,[2,3,5]],
		"/(app)/user/settings/registered-licenses": [30,[2,3,5]],
		"/(app)/user/settings/two-factor": [31,[2,3,5]],
		"/(app)/user/settings/unclaimed-licenses": [32,[2,3,5]],
		"/(app)/verify-email": [33,[2]],
		"/(app)/[...path]": [~8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';