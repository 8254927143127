import * as Sentry from '@sentry/sveltekit';

export async function initSentry() {
	const dev = import.meta.env.DEV;

	// wait 1 second
	if (dev) await new Promise((resolve) => setTimeout(resolve, 1000));

	Sentry.init({
		dsn: 'https://cb55b0a3001b7b468d06244c20c7c71f@o4507403111235584.ingest.de.sentry.io/4508165631311952',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0
	});
}

initSentry();
